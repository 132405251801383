import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export function typedAction<T extends string>(type: T): { type: T };

export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P };

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export const initialState = {
  isDrawerOpened: false,
};

export const DrawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    toggleDrawer: (state, action: PayloadAction<boolean>) => {
      console.log('Inside reducer');
      console.log(action.payload);
      // return { ...state };
      return { ...state, isDrawerOpened: action.payload };
    },
  },
});
