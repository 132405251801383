import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { RouteComponentProps } from "react-router-dom";
import { VideoQuality, VideoActiveState } from "@zoom/videosdk";
import classnames from "classnames";
import ZoomContext from "../../context/zoom-context";
import ZoomMediaContext from "../../context/media-context";
import Avatar from "./components/avatar";
import { Box } from "@material-ui/core";
import VideoFooter from "./components/video-footer";
import { useShare } from "./hooks/useShare";
import { useParticipantsChange } from "./hooks/useParticipantsChange";
import { useCanvasDimension } from "./hooks/useCanvasDimension";
import { usePrevious, useMount } from "../../hooks";
import { Participant } from "../../interfaces/interface";
import "./video.scss";
import withZoom from "../../wrappers/withZoom";

const VideoContainer = (props: any) => {
  const zmClient = props.client;

  const {
    mediaStream,
    video: { decode: isVideoDecodeReady },
  } = useContext(ZoomMediaContext);
  const videoRef = useRef(null);
  const [participants, setParticipants] = useState([]);
  const [activeVideo, setActiveVideo] = useState();
  const [activeSpeaker, setActiveSpeaker] = useState();
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);

  const previousActiveVideo = usePrevious(activeVideo);
  useParticipantsChange(zmClient, (payload) => {
    setParticipants(payload);
  });
  const onActiveVideoChange = useCallback((payload: any) => {
    const { state, userId } = payload;
    if (state === VideoActiveState.Active) {
      setActiveVideo(userId);
    } else if (state === VideoActiveState.Inactive) {
      setActiveVideo(0);
    }
  }, []);
  const onActiveSpeakerChange = useCallback((payload: any) => {
    if (Array.isArray(payload) && payload.length > 0) {
      const { userId } = payload[0];
      setActiveSpeaker(userId);
    }
  }, []);
  useEffect(() => {
    zmClient.on("video-active-change", onActiveVideoChange);
    zmClient.on("active-speaker", onActiveSpeakerChange);
    return () => {
      zmClient.off("video-active-change", onActiveVideoChange);
      zmClient.off("active-speaker", onActiveSpeakerChange);
    };
  }, [zmClient, onActiveVideoChange, onActiveSpeakerChange]);

  const activeUser = useMemo(() => {
    let user = undefined;
    if (activeVideo) {
      user = participants.find((user: any) => user.userId === activeVideo);
    } else if (activeSpeaker) {
      user = participants.find((user: any) => user.userId === activeSpeaker);
    } else {
      user = participants.find((user: any) => user.isHost);
    }
    if (!user) {
      user = zmClient.getCurrentUserInfo();
    }
    return user;
  }, [activeSpeaker, participants, activeVideo, zmClient]);
  useEffect(() => {
    if (mediaStream && videoRef.current && isVideoDecodeReady) {
      if (activeVideo && previousActiveVideo !== activeVideo) {
        /**
         * Can not specify the width and height of the rendered video, also applied to the position of video.
         * Passing these arguments just for consistency.
         */
        mediaStream.renderVideo(
          videoRef.current,
          activeVideo,
          canvasDimension.width,
          canvasDimension.height,
          0,
          0,
          VideoQuality.Video_360P as any
        );
      } else if (activeVideo === 0 && previousActiveVideo) {
        mediaStream.stopRenderVideo(videoRef.current, previousActiveVideo);
      }
    }
  }, [
    mediaStream,
    activeVideo,
    previousActiveVideo,
    isVideoDecodeReady,
    canvasDimension,
  ]);
  useEffect(() => {
    if (mediaStream) {
      setActiveVideo(mediaStream?.getActiveVideoId());
    }
  }, [mediaStream]);
  return (
    <Box className="viewport">
      <Box className="video-container">
        <Box className="video-canvas" id="video-canvas">
          <canvas
            ref={videoRef}
            height="700"
            width="800"
          />
        </Box>
        {activeUser && (
          <Avatar
            participant={activeUser}
            isActive={false}
            className="single-view-avatar"
          />
        )}
      </Box>
    </Box>
  );
};

export default withZoom(VideoContainer);
