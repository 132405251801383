import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export function typedAction<T extends string>(type: T): { type: T };

export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P };

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export const initialState = {
  title: "Lumin Community Video",
  data: {
    displayName: "",
    photoURL: null,
    email: null,
    shortcuts: [],
  },
  communities: [],
  current_community: null,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    storeUser: (state, action: PayloadAction<typeof typedAction>) => {
      return { ...state, ...action.payload };
    },
  },
});
