import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { store } from './store/store';
import { Provider } from 'react-redux';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import ZoomContext from './store/zoom-context/zoomClientContext';
import ZoomVideo from '@zoom/videosdk';
const zmClient = ZoomVideo.createClient();

Bugsnag.start({
  apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
  plugins: [new BugsnagPluginReact(React)]
})

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ZoomContext.Provider value={zmClient}>
        <BrowserRouter>  
          <App />
        </BrowserRouter>
      </ZoomContext.Provider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);