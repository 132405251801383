import React from "react";
import { Drawer, Avatar } from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getParticipants } from "../store/participant/ParticipantsSlice";
import { storeCommunity } from "../store/store";

const styles = {
  sideNav: {
    marginTop: "-60px",
    zIndex: 3,
    marginBottom: "20px",
    marginLeft: "0px",
    position: "fixed",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
};

export default function CommunityDrawer(props: any) {
  const dispatch = useDispatch();

  const closeDrawer = () => {
    props.setIsDrawerOpened(false);
  };

  const handleCommunitySelectionChange = (e: Event, communityId: string) => {
    const selectedCommunity = props.communities?.find(
      (community: any) => community?.uuid === communityId
    );
    dispatch(storeCommunity(selectedCommunity));
    dispatch(getParticipants(communityId));
    closeDrawer();
  };

  return (
    <Drawer
      variant="temporary"
      open={props.isDrawerOpened}
      onClose={() => closeDrawer()}
      style={styles.sideNav}
      data-testid="participants"
    >
      {props.communities?.map((community: any) => (
        <List key={community?.id}>
          <Link to={`/${community?.uuid}`} style={styles.link}>
            <ListItem
              key={community?.uuid}
              button
              onClick={(e: Event) =>
                handleCommunitySelectionChange(e, community?.uuid)
              }
            >
              <ListItemIcon>
                <Avatar
                  alt="Community Avatar"
                  src={community?.thumb_image_url}
                />
              </ListItemIcon>
              <ListItemText primary={community?.name} />
            </ListItem>
          </Link>
        </List>
      ))}
    </Drawer>
  );
}
