import React from "react";
import hoistStatics from "hoist-non-react-statics";
import ZoomContext from '../store/zoom-context/zoomClientContext';

function withZoom(Component: any){
  const displayName = `withZoom(${Component.displayName || Component.name})`;
  
  const ZoomWrapper = (props: any) => {
    const { wrappedComponentRef, ...remainingProps } = props;
    return (
      <ZoomContext.Consumer>
         {(context: any) => {
           return (
             <Component
               {...remainingProps}
               client={context}
               ref={wrappedComponentRef}
             />
           );
        }}
      </ZoomContext.Consumer>
    );
  };
  
  ZoomWrapper.displayName = displayName;
  ZoomWrapper.WrappedComponent = Component;
  return hoistStatics(ZoomWrapper, Component);
}

export default withZoom;