import React from "react";
import { Tooltip, Menu, Dropdown } from "antd";
import { Button } from "@material-ui/core";
import { Videocam, VideocamOff } from "@material-ui/icons";
import {
  CheckOutlined,
  UpOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import "./camera.scss";
import { MediaDevice } from "../video-types";
interface CameraButtonProps {
  isStartedVideo: boolean;
  onCameraClick: () => void;
  onSwitchCamera: (deviceId: string) => void;
  className?: string;
  cameraList?: MediaDevice[];
  activeCamera?: string;
}
const CameraButton = (props: CameraButtonProps) => {
  const {
    isStartedVideo,
    className,
    cameraList,
    activeCamera,
    onCameraClick,
    onSwitchCamera,
  } = props;
  const onMenuItemClick = (payload: { key: any }) => {
    onSwitchCamera(payload.key);
  };

  return (
    <div className={classNames("camera-footer", className)}>
      <Button
        className={classNames("camere-button", className)}
        icon={isStartedVideo ? <Videocam /> : <VideocamOff />}
        shape="circle"
        size="large"
        onClick={() => onCameraClick()}
      >
        {isStartedVideo ? <Videocam /> : <VideocamOff />}
      </Button>
    </div>
  );
};
export default CameraButton;
