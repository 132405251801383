import { configureStore } from "@reduxjs/toolkit";
import { UserSlice } from "./user/UserSlice";
import { ParticipantsSlice } from "./participant/ParticipantsSlice";
import { CommunitySlice } from "./community/CommunitySlice";
import { SessionSlice } from "./session/SessionSlice";
import { DrawerSlice } from "./drawer/DrawerSlice";

export const store = configureStore({
  reducer: {
    user: UserSlice.reducer,
    community: CommunitySlice.reducer,
    participants: ParticipantsSlice.reducer,
    session: SessionSlice.reducer,
    drawer: DrawerSlice.reducer,
  },
});

export const { storeUser } = UserSlice.actions;
export const { storeCommunity } = CommunitySlice.actions;
export const { toggleInviteStatus } = ParticipantsSlice.actions;
export const { storeSession, storeSessionId } = SessionSlice.actions;
export const { toggleDrawer } = DrawerSlice.actions;

export type RootState = ReturnType<typeof store.getState>;

export const selectedUser = (state: RootState) => state.user;
export const selectedCommunity = (state: RootState) => state.community;
export const participantList = (state: RootState) => state.participants;
export const currentSession = (state: RootState) => state.session;
export const drawerToggleState = (state: RootState) => state.drawer;

export type AppDispatch = typeof store.dispatch;

export default store;