import React, {
  useReducer,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@material-ui/core";
import Footer from "./Footer";
import "./Video.scss";
import produce from "immer";
import ZoomVideo, { ConnectionState } from "@zoom/videosdk";
import {
  selectedCommunity,
  selectedUser,
  participantList,
  currentSession,
} from "../store/store";
import { getParticipants } from "../store/participant/ParticipantsSlice";
import { storeCommunity, storeSession } from "../store/store";
import ZoomMediaContext from "../context/media-context";
import ZoomContext from "../store/zoom-context/zoomClientContext";
import withZoom from "../wrappers/withZoom";
import { MediaStream } from "../interfaces/interface";
import VideoContainer from "./video/VideoContainer";
import VideoSingle from "./video/video-single";
import LoadingLayer from "../components/icons/loading-layer";
import { useHistory } from "react-router-dom";
import Landing from "./video/components/landing";

interface meetingArgs {
  meetingArgs: {
    sdkKey: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
  };
}

const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case "audio-encode": {
      draft.audio.encode = action.payload;
      break;
    }
    case "audio-decode": {
      draft.audio.decode = action.payload;
      break;
    }
    case "video-encode": {
      draft.video.encode = action.payload;
      break;
    }
    case "video-decode": {
      draft.video.decode = action.payload;
      break;
    }
    default:
      break;
  }
}, mediaShape);

const Video = (props: any) => {
  const user = useSelector(selectedUser);
  const community = useSelector(selectedCommunity);
  const participants = useSelector(participantList);
  const session = useSelector(currentSession);
  let history = useHistory();

  const zmClient = props.client;
  const sessiontopic = session.sessionId;
  const sessiontoken = session.token;
  const name = user?.community?.contact_shortuid;
  const currentCommunityUuid = community.uuid;

  const [loading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState({} as MediaStream | null);
  const [status, setStatus] = useState("closed");
  const [isFailover, setIsFailover] = useState(false as boolean);
  const [isSupportGalleryView, setIsSupportGalleryView] = useState(true);
  const [isParticipantDrawerOpened, setIsParticipantDrawerOpened] =
    useState(false);

  const onConnectionChange = useCallback(
    (payload: any) => {
      const stream = zmClient.getMediaStream();
      setMediaStream(stream);
      setIsSupportGalleryView(stream.isSupportMultipleVideos());
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus("connecting");
        const { reason } = payload;
        if (reason === "failover") {
          setLoadingText("Session Disconnected,Try to reconnect");
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus("connected");
        if (isFailover) {
          setIsLoading(false);
        }
      } else if (payload.state === ConnectionState.Closed) {
        setStatus("closed");
        if (payload.reason === "ended by host") {
          console.warn({
            title: "Meeting ended",
            content: "This meeting has been ended by host",
          });
        }
      }
    },
    [isFailover, zmClient]
  );
  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === "success" });
  }, []);

  useEffect(() => {
    const init = async () => {
      await zmClient.init("en-US");
    };
    init();
  }, [zmClient]);

  useEffect(() => {
    zmClient.on("connection-change", onConnectionChange);
    zmClient.on("media-sdk-change", onMediaSDKChange);
    return () => {
      zmClient.off("connection-change", onConnectionChange);
      zmClient.off("media-sdk-change", onMediaSDKChange);
    };
  }, [onConnectionChange, onMediaSDKChange, zmClient]);

  const onLeaveOrJoinSession = useCallback(
    async (topic: any = sessiontopic, token: any = sessiontoken) => {
      try {
        if (status === "closed") {
          setIsLoading(true);
          setLoadingText("Joining the session...");
          zmClient.join(topic, token, name);
          setIsLoading(false);
        } else if (status === "connected") {
          zmClient.leave();
          ZoomVideo.destroyClient();
          history.push(`/${currentCommunityUuid}`);
          console.warn("You have left the session.");
        }
      } catch (e: any) {
        setIsLoading(false);
        console.error(e.reason);
      }
    },
    [status, name, sessiontopic, sessiontoken, history, zmClient]
  );

  let actionText: any;
  if (status === "connected") {
    actionText = "DISCONNECT";
  } else if (status === "closed") {
    actionText = "Join";
  }

  return (
    <>
      {loading && <LoadingLayer content={loadingText} />}
      {!loading && (
        <ZoomMediaContext.Provider value={{ ...mediaState, mediaStream }}>
          {user?.email && (
            <>
              <Route
                path="/"
                exact
                render={(props: any) => (
                  <Landing
                    isParticipantDrawerOpened={isParticipantDrawerOpened}
                    setIsParticipantDrawerOpened={setIsParticipantDrawerOpened}
                  />
                )}
              />
              <Route
                path="/:currentCommunityUuid"
                exact
                render={(props: any) => (
                  <Landing
                    isParticipantDrawerOpened={isParticipantDrawerOpened}
                    setIsParticipantDrawerOpened={setIsParticipantDrawerOpened}
                  />
                )}
              />
            </>
          )}
          <Route
            path="/:currentCommunityUuid/video"
            exact
            render={(props: any) => (
              <>
                {isSupportGalleryView ? (
                  <VideoContainer
                    {...props}
                    status={status}
                    onLeaveOrJoinSession={onLeaveOrJoinSession}
                  />
                ) : (
                  <VideoSingle
                    {...props}
                    status={status}
                    onLeaveOrJoinSession={onLeaveOrJoinSession}
                  />
                )}
              </>
            )}
          />
        </ZoomMediaContext.Provider>
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
      <Footer
        user={user}
        status={status}
        actionText={actionText}
        community={community}
        session={session}
        participants={participants}
        getParticipants={getParticipants}
        storeCommunity={storeCommunity}
        storeSession={storeSession}
        onLeaveOrJoinSession={onLeaveOrJoinSession}
        mediaStream={mediaStream}
        isParticipantDrawerOpened={isParticipantDrawerOpened}
        setIsParticipantDrawerOpened={setIsParticipantDrawerOpened}
      />
    </>
  );
};

export default withZoom(Video);
