import axios from "axios";
import { v4 as uuid } from 'uuid';
import { toast } from "react-toastify";

const ACCESS_TOKEN = "access_token_lumin_video";

class LumenCloudService {
  baseDomain: string;
  baseUrl: string;
  baseUrlV2: string;
  dispatch: any;
  constructor() {
    this.baseDomain = "https://api.mylumin.net";
    this.baseUrl = "https://api.mylumin.net/api/v1";
    this.baseUrlV2 = "https://api.mylumin.net/api/v2";

    this.init = this.init.bind(this);
    this.handleResponse = this.handleResponse.bind(this)
  }

  init(dispatch: any) {
    this.dispatch = dispatch;
  }

  getHeadersNoAuth() {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    }
  }

  async inviteParticipants(participantId: string, sessionId: string) {
    const inviteParticipantUrl = `${this.baseUrlV2}/push/voip/${participantId}?command=connect&room_name=${sessionId}`;
    await axios
    .get(inviteParticipantUrl, {
      headers: await this.getHeaders(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.warn(
        `LumenCloudService ERROR: [${error.request?.responseURL}]`,
        error.response?.data,
        error.response?.status,
        error.message,
      );
      if(error.response?.status === 400) {
        toast.error(`${error?.message}`);
      } else {
        toast.error(error?.response?.data.error);
      }
    });
  }

  handleResponse(response: any) {
    return response;
  }

  handleError(error: any) {
    console.warn(
      `LumenCloudService ERROR: [${error.request?.responseURL}]`,
      error.response?.data,
      error.response?.status,
      error.message,
      error
    );
    toast.error(`${error.response.data.error}`);
    return error
  }

  async getVideoToken(sessionId: string, communityId: string) {
    const url = this.baseUrl + `/video/token?session_id=${sessionId}&community_id=${communityId}`;
    const headers = await this.getHeaders();

    return axios({
      method: "get",
      url: url,
      headers: headers,
    })
      .then(this.handleResponse)
      .catch(this.handleError);
  }
}

export default new LumenCloudService();
