import React, { useCallback } from "react";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { ControlPoint, RemoveCircleOutline } from "@material-ui/icons";

import { useHistory } from "react-router-dom";

import { v4 as uuid } from "uuid";
import LumenCloudService from "../services/lumenCloudService";

import {
  toggleInviteStatus,
  storeSession,
  storeSessionId,
} from "../store/store";

const useStyles = makeStyles({
  MuiDrawer: {
    width: "700px",
    backgroundColor: "#252F3E",
    zIndex: 0.5,
    overflow: "hidden",
    marginBottom: "50px",
    "&::after": {
      opacity: 0.8,
    },
  },
  gridRoot: {
    overflow: "hidden",
    paddingTop: "100px",
    backgroundColor: "#252F3E",
    spacing: 0,
    flexWrap: "nowrap"
  },
  invitelistWrapper: {
    backgroundColor: "#EEEEEE",
    // marginBottom: "100px",
    height: "50%",
  },
  invitedlistWrapper: {
    backgroundColor: "#EEEEEE",
    height: "50%",
    // marginBottom: "100px",
  },
  invite: {
    width: "200px",
    backgroundColor: "#EEEEEE",
    padding: "5px",
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden",
    overflowX: "hidden",
    margin: "5px",
    marginLeft: "10px",
  },
  invited: {
    width: "200px",
    padding: "5px",
    backgroundColor: "#EEEEEE",
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden",
    overflowX: "hidden",
    margin: "5px",
    marginRight: "10px",
  },
  heading: {
    display: "flex",
    justifyContent: "space-around",
  },
  inviteListItem: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#404040",
    margin: "2px",
  },
  invitedListItem: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#b5b2b2",
    margin: "2px",
    color: "black",
  },
  inviteList: {
    height: "80vh",
    maxHeight: "100%",
    overflow: "auto",
    color: "#EEEEEE",
    backgroundColor: "black",
    padding: 0,
    overflowX: "hidden",
    // padding: "20px",
  },
  invitedList: {
    height: "80vh",
    maxHeight: "100%",
    overflow: "auto",
    color: "#EEEEEE",
    overflowX: "hidden",
    padding: 0,
  },
  listSubWrapper: {
    padding: "10px",
    margin: "auto",
    height: "170%",
    overflowY: "hidden",
    overflowX: "hidden",
  },
  drawerFooter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "space-around",
    height: "70px",
  },
  callButton: {
    height: "100%",
    backgroundColor: "#108816",
    marginRight: "10px",
    marginBottom: "10px",
    "&:hover": {
      background: "darkgreen",
    },
    color: "white",
  },
});

export default function ParticipantDrawer(props: any) {
  const dispatch = useDispatch();
  const styles = useStyles();
  const history = useHistory();

  const inviteParticipants = useCallback(
    (participants: any, sessionId: string) => {
      const participantsToInvite = filterInvitedParticipants(participants);
      participantsToInvite.map((participant: any) => {
        return LumenCloudService.inviteParticipants(
          participant.contact_shortuid,
          sessionId
        );
      });
    },
    []
  );

  const closeDrawer = () => {
    props.setIsDrawerOpened(false);
  };

  const handleToggleInviteStatus = (participantId: any) => {
    dispatch(toggleInviteStatus(participantId));
  };

  const filterInvitedParticipants = (participants: any) => {
    return participants.filter(
      (participant: any) => participant.invited === true
    );
  };

  const numberOfInvitedParticipants = filterInvitedParticipants(
    props.participants
  ).length;

  const handleStartCall = async () => {
    const currentCommunityUuid = props.community.uuid;
    const getAccessToken = async () => {
      const sessionId = uuid();
      const session = await LumenCloudService.getVideoToken(
        sessionId,
        props.community.uuid
      );
      dispatch(storeSession(session.data));
      dispatch(storeSessionId(sessionId));
      return [session, sessionId];
    };
    closeDrawer();
    if (!props.session.token) {
      const sessionDetails = await getAccessToken();
      history.push(`/${currentCommunityUuid}/video`);
      props.onLeaveOrJoinSession(
        sessionDetails[1],
        sessionDetails[0].data.token
      );
      inviteParticipants(props.participants, sessionDetails[1]);
    } else {
      history.push(`/${currentCommunityUuid}/video`);
      props.onLeaveOrJoinSession();
      inviteParticipants(props.participants, props.session.sessionId);
    }
  };

  return (
    <div>
      <Drawer
        anchor="bottom"
        variant="temporary"
        open={props.isDrawerOpened}
        onClose={() => closeDrawer()}
        classes={{ paper: styles.MuiDrawer }}
      >
        <Grid direction="row" container className={styles.gridRoot}>
          <Grid container item sm={6} md={6} className={styles.invite}>
            <Box className={styles.invitelistWrapper}>
              <Box className={styles.heading}>
                <h2>Invite Participants</h2>
              </Box>
              <Box className={styles.listSubWrapper}>
                <List className={styles.inviteList}>
                  {props.participants.map(
                    (participant: any) =>
                      !participant.invited && (
                        <ListItem
                          button
                          key={participant.id}
                          className={styles.inviteListItem}
                          onClick={() =>
                            handleToggleInviteStatus(participant.uuid)
                          }
                        >
                          <Box display="flex">
                            <ListItemIcon>
                              <Avatar
                                alt="Community Avatar"
                                src={participant.thumb_image_url}
                              />
                            </ListItemIcon>
                            <ListItemText primary={participant.name} />
                          </Box>
                          <Box>
                            <ControlPoint />
                          </Box>
                        </ListItem>
                      )
                  )}
                </List>
              </Box>
            </Box>
          </Grid>
          <Grid container item sm={6} md={6} className={styles.invited}>
            <Box className={styles.invitedlistWrapper}>
              <Box className={styles.heading}>
                <h2>{numberOfInvitedParticipants} Invited Participants</h2>
              </Box>
              <Box className={styles.listSubWrapper}>
                <List className={styles.invitedList}>
                  {props.participants.map(
                    (participant: any) =>
                      participant.invited && (
                        <ListItem
                          button
                          key={participant.id}
                          className={styles.invitedListItem}
                          onClick={() =>
                            handleToggleInviteStatus(participant.uuid)
                          }
                        >
                          <Box display="flex">
                            <ListItemIcon>
                              <Avatar
                                alt="Community Avatar"
                                src={participant.thumb_image_url}
                              />
                            </ListItemIcon>
                            <ListItemText primary={participant.name} />
                          </Box>
                          <Box>
                            <RemoveCircleOutline />
                          </Box>
                        </ListItem>
                      )
                  )}
                </List>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className={styles.drawerFooter}>
          <Button
            className={styles.callButton}
            onClick={() => handleStartCall()}
            disabled={numberOfInvitedParticipants < 1}
          >
            <span>
              <strong>Start Call</strong>
            </span>
          </Button>
        </Box>
      </Drawer>
    </div>
  );
}
