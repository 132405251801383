import { Button } from '@material-ui/core';
import React from 'react';
import { APP_URL } from '../settings';

interface Iprops {
    email: string
}

function LogInOut(props: Iprops) { 
    let message = (props.email)
      ? 'Sign out'
      : 'Sign in';

    let path = (props.email)
      ? '/logout'
      : '/login';
  return(
    <Button style={{ backgroundColor: 'skyblue', color: 'white' }}>
      <a href={APP_URL + path}>{message}</a>
    </Button>
  )
}

export default LogInOut;