import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';
import { PRODUCTION_LUMIN_HOST } from '../../settings';

const ACCESS_TOKEN = "access_token_lumin_video";

const initialState = {
  participants: [],
  loading: false,
}

export function typedAction<T extends string>(type: T): { type: T };

export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P };

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export const getParticipants: any = createAsyncThunk(
  'participants/getParticipants',
  async (communityId: string) => {
    const fusionAuthUserUrl = `${PRODUCTION_LUMIN_HOST}/api/v3/${communityId}/accounts`;
    const participants = axios
        .get(fusionAuthUserUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.warn("Cannot retrieve participants", error);
          console.log(error);
        });
    return participants;
});

export const ParticipantsSlice = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    toggleInviteStatus: (state, action: PayloadAction<typeof typedAction>) => {
      const participants: any = [...state.participants]
      const index = participants.findIndex((participant: any) => participant?.uuid === action.payload)
      participants[index].invited = !participants[index].invited;
    },
  },
  extraReducers: {
    [getParticipants.pending]: (state) => {
      state.loading = true
    },
    [getParticipants.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.participants = payload?.data?.map((participant: any)=> ({ ...participant, invited: false }))
    },
    [getParticipants.rejected]: (state) => {
      state.loading = false
    },
  },
})
