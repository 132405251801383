import React, { useContext, useRef } from "react";
import classnames from "classnames";
import { RouteComponentProps } from "react-router";
// import ZoomContext from '../../context/zoom-context';
import ZoomContext from "../../store/zoom-context/zoomClientContext";
import ZoomMediaContext from "../../context/media-context";
import Avatar from "./components/avatar";
import VideoFooter from "./components/video-footer";
import { useCanvasDimension } from "./hooks/useCanvasDimension";
import { useGalleryLayout } from "./hooks/useGalleryLayout";
import { usePagination } from "./hooks/usePagination";
import { useActiveVideo } from "./hooks/useActiveVideo";
import Pagination from "./components/pagination";
import "./video.scss";
import { isSupportWebCodecs } from "../../utils/platform";
import withZoom from "../../wrappers/withZoom";

const VideoContainer = (props: any) => {
  const zmClient = props.client;
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady },
  } = useContext(ZoomMediaContext);
  const videoRef = useRef(null);
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const activeVideo = useActiveVideo(zmClient);
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(
    zmClient,
    canvasDimension
  );
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize,
    }
  );

  return (
    <div className="viewport">
      <div className={"video-container"}>
        <div className="video-canvas" id="video-canvas">
          <canvas width="800" height="700" ref={videoRef} />
        </div>
        <ul className="avatar-list">
          {visibleParticipants.map((user: any, index: any) => {
            if (index > videoLayout.length - 1) {
              return null;
            }
            const dimension = videoLayout[index];
            const { width, height, x, y } = dimension;
            const { height: canvasHeight } = canvasDimension;
            return (
              <Avatar
                participant={user}
                key={user.userId}
                isActive={activeVideo === user.userId}
                style={{
                  width: `${width}px`,
                  height: `${height}px`,
                  top: `${canvasHeight - y - height}px`,
                  left: `${x}px`,
                }}
              />
            );
          })}
        </ul>
      </div>
      {totalPage > 1 && (
        <Pagination page={page} totalPage={totalPage} setPage={setPage} />
      )}
    </div>
  );
};

export default withZoom(VideoContainer);
