/* eslint-disable no-nested-ternary */
import React from "react";
import { Menu, Tooltip, Dropdown } from "antd";
import { Button } from "@material-ui/core";
import { Mic, MicOff } from "@material-ui/icons";
import classNames from "classnames";
import {
  AudioOutlined,
  AudioMutedOutlined,
  CheckOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { IconFont } from "../../icons/icon-font";
import "./microphone.scss";
import { MediaDevice } from "../video-types";
const { Button: DropdownButton } = Dropdown;
interface MicrophoneButtonProps {
  isStartedAudio: boolean;
  isMuted: boolean;
  onMicrophoneClick: () => void;
  onMicrophoneMenuClick: (key: string) => void;
  className?: string;
  microphoneList?: MediaDevice[];
  speakerList?: MediaDevice[];
  activeMicrophone?: string;
  activeSpeaker?: string;
}
const MicrophoneButton = (props: MicrophoneButtonProps) => {
  const {
    isStartedAudio,
    isMuted,
    className,
    microphoneList,
    speakerList,
    activeMicrophone,
    activeSpeaker,
    onMicrophoneClick,
    onMicrophoneMenuClick,
  } = props;
  const tooltipText = isStartedAudio
    ? isMuted
      ? "unmute"
      : "mute"
    : "start audio";
  const menu = [];
  if (microphoneList && microphoneList.length) {
    menu.push({
      group: "microphone",
      title: "Select a Microphone",
      items: microphoneList.map((i) => ({
        label: i.label,
        value: i.deviceId,
        checked: activeMicrophone === i.deviceId,
      })),
    });
  }
  if (speakerList && speakerList.length) {
    menu.push({
      group: "speaker",
      title: "Select a speaker",
      items: speakerList.map((i) => ({
        label: i.label,
        value: i.deviceId,
        checked: activeSpeaker === i.deviceId,
      })),
    });
  }
  menu.push({
    items: [
      {
        label: "Leave Audio",
        value: "leave audio",
      },
    ],
  });
  const onMenuItemClick = (payload: { key: any }) => {
    onMicrophoneMenuClick(payload.key);
  };

  return (
    <div className={classNames("microphone-footer", className)}>
      <Button
        className={"microphone-button"}
        size="large"
        shape="circle"
        onClick={() => onMicrophoneClick()}
      >
        {isMuted ? <MicOff /> : <Mic />}
      </Button>
    </div>
  );
};

export default MicrophoneButton;
