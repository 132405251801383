import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { Box } from "@material-ui/core";
import CameraButton from "./camera";
import MicrophoneButton from "./microphone";
import { Button } from '@material-ui/core';
import ZoomMediaContext from "../../../context/media-context";
import { useUnmount } from "../../../hooks";
import "./video-footer.scss";
import withZoom from "../../../wrappers/withZoom";
interface VideoFooterProps {
  className?: string;
  shareRef?: any;
  sharing?: boolean;
}
const isAudioEnable = typeof AudioWorklet === "function";
const VideoFooter = (props: any) => {
  const [isStartedAudio, setIsStartedAudio] = useState(true);
  const [isStartedVideo, setIsStartedVideo] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [activeMicrophone, setActiveMicrophone] = useState("");
  const [activeSpeaker, setActiveSpeaker] = useState("");
  const [activeCamera, setActiveCamera] = useState("");
  const [micList, setMicList] = useState([]);
  const [speakerList, setSpeakerList] = useState([]);
  const [cameraList, setCameraList] = useState([]);
  // const { mediaStream } = useContext(ZoomMediaContext);
  const mediaStream = props.mediaStream;
  // const zmClient = useContext(ZoomContext);
  const zmClient = props.client;

  const onCameraClick = useCallback(async () => {
    if (isStartedVideo) {
      await mediaStream?.stopVideo();
      setIsStartedVideo(false);
    } else {
      await mediaStream?.startVideo();
      setIsStartedVideo(true);
    }
  }, [mediaStream, isStartedVideo]);

  const onMicrophoneClick = useCallback(async () => {
    if (isStartedAudio) {
      if (isMuted) {
        await mediaStream?.unmuteAudio();
        setIsMuted(false);
      } else {
        await mediaStream?.muteAudio();
        setIsMuted(true);
      }
    } else {
      await mediaStream?.startAudio();
      setIsStartedAudio(true);
    }
  }, [mediaStream, isStartedAudio, isMuted]);
  const onMicrophoneMenuClick = async (key: string) => {
    if (mediaStream) {
      const [type, deviceId] = key.split("|");
      if (type === "microphone") {
        if (deviceId !== activeMicrophone) {
          await mediaStream.switchMicrophone(deviceId);
          setActiveMicrophone(mediaStream.getActiveMicrophone());
        }
      } else if (type === "speaker") {
        if (deviceId !== activeSpeaker) {
          await mediaStream.switchSpeaker(deviceId);
          setActiveSpeaker(mediaStream.getActiveSpeaker());
        }
      } else if (type === "leave audio") {
        await mediaStream.stopAudio();
        setIsStartedAudio(false);
      }
    }
  };
  const onSwitchCamera = async (key: string) => {
    if (mediaStream) {
      if (activeCamera !== key) {
        await mediaStream.switchCamera(key);
        setActiveCamera(mediaStream.getActiveCamera());
      }
    }
  };
  const onHostAudioMuted = useCallback((payload: any) => {
    const { action, source, type } = payload;
    if (action === "join" && type === "computer") {
      setIsStartedAudio(true);
    } else if (action === "leave") {
      setIsStartedAudio(false);
    } else if (action === "muted") {
      setIsMuted(true);
      if (source === "passive(mute one)") {
        console.log("Host muted you");
      }
    } else if (action === "unmuted") {
      setIsMuted(false);
      if (source === "passive") {
        console.log("Host unmuted you");
      }
    }
  }, []);

  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());
      setCameraList(mediaStream.getCameraList());
      setActiveMicrophone(mediaStream.getActiveMicrophone());
      setActiveSpeaker(mediaStream.getActiveSpeaker());
      setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream]);
  useEffect(() => {
    zmClient.on("current-audio-change", onHostAudioMuted);
    // zmClient.on('passively-stop-share', onPassivelyStopShare);
    zmClient.on("device-change", onDeviceChange);
    return () => {
      zmClient.off("current-audio-change", onHostAudioMuted);
      // zmClient.off('passively-stop-share', onPassivelyStopShare);
      zmClient.off("device-change", onDeviceChange);
    };
    // }, [zmClient, onHostAudioMuted, onPassivelyStopShare, onDeviceChange]);
  }, [zmClient, onHostAudioMuted, onDeviceChange]);

  useEffect(() => {
    setTimeout(() => mediaStream?.startVideo(), 1000);
    setTimeout(() => mediaStream?.startAudio(), 1000);
  }, [])

  useUnmount(() => {
    if (isStartedAudio) {
      mediaStream?.stopAudio();
    }
    if (isStartedVideo) {
      mediaStream?.stopVideo();
    }
  });
  return (
    <Box className="video-footer" display="flex">
      <Box display="flex">
      {isAudioEnable && (
        <MicrophoneButton
          isStartedAudio={isStartedAudio}
          isMuted={isMuted}
          onMicrophoneClick={onMicrophoneClick}
          onMicrophoneMenuClick={onMicrophoneMenuClick}
          microphoneList={micList}
          speakerList={speakerList}
          activeMicrophone={activeMicrophone}
          activeSpeaker={activeSpeaker}
        />
      )}
      <CameraButton
        isStartedVideo={isStartedVideo}
        onCameraClick={onCameraClick}
        onSwitchCamera={onSwitchCamera}
        cameraList={cameraList}
        activeCamera={activeCamera}
      />
      </Box>
      <Box display="flex" justifyContent="flex-end">
      {props.status === "connected" && (
        <Button type="link" className="navleave" onClick={props.onLeaveOrJoinSession}>
          {props.actionText}
        </Button>
      )}
      </Box>
    </Box>
  );
};
export default withZoom(VideoFooter);
