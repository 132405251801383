import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectedUser, storeUser, storeCommunity, toggleDrawer } from "./store/store";
import { getParticipants } from "./store/participant/ParticipantsSlice";
import "./App.css";
import Video from "./components/Video";
// import Video from "./components/vidsdk/video";
import LogInOut from "./components/LogInOut";
import Login from "./main/login/Login";
import Logout from "./main/logout/logout";
import Callback from "./main/callback/Callback";
import FusionAuthService from "./services/fusionAuthService";
import Landing from './components/video/components/landing';

export default function App() {
  const user = useSelector(selectedUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (FusionAuthService.isAuthenticated()) {
      FusionAuthService.getUserData()
        .then((user: any) => {
          dispatch(storeUser(user));
          dispatch(storeCommunity(user?.community));
          dispatch(getParticipants(user?.community?.uuid));
        })
        .catch((error) => {
          console.log("Error");
        });
    }
  }, [dispatch]);

  return (
    <div className="App">
      <header className="App-header">
        <h1 data-testid="header">{user["title"]}</h1>
        <LogInOut email={user?.email} />
      </header>
      <Route path="/" component={() => <Video />} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/callback" component={Callback} />
    </div>
  );
}
