import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TemporaryDrawer from "./CommunityDrawer";
import ParticipantDrawer from "./ParticipantDrawer";
import Avatar from "@material-ui/core/Avatar";
import VideoFooter from "../components/video/components/video-footer";
import { Add, ArrowDownward } from "@material-ui/icons";

import { useSelector } from "react-redux";
import { currentSession, selectedCommunity } from "../store/store";

export default function Footer(props: any) {
  const session = useSelector(currentSession);
  const community = useSelector(selectedCommunity);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);


  const handleDrawerClick = () => {
    setIsDrawerOpened(!isDrawerOpened);
  };

  const handleParticipantDrawerClick = () => {
    props.setIsParticipantDrawerOpened(!props.isParticipantDrawerOpened);
  };
  return (
    <footer>
      <Box
        bgcolor="#282c34"
        color="black"
        position="absolute"
        width="100%"
        bottom="0"
      >
        <Grid container>
          <Grid item xs={12} sm={1} style={{ display: "flex" }}>
            <Box display="flex">
              <Button onClick={() => handleDrawerClick()}>
                <Avatar
                  alt="Community Avatar"
                  src={props.community?.thumb_image_url}
                />
              </Button>
              <TemporaryDrawer
                isDrawerOpened={isDrawerOpened}
                setIsDrawerOpened={setIsDrawerOpened}
                communities={props.user?.communities}
                dispatchParticipants={props.getParticipants}
                getCommunity={props.storeCommunity}
              />
              <Button
                size="large"
                onClick={() => handleParticipantDrawerClick()}
                style={{ color: "white" }}
              >
                {props.isParticipantDrawerOpened ? <ArrowDownward /> : <Add />}
              </Button>
              <ParticipantDrawer
                isDrawerOpened={props.isParticipantDrawerOpened}
                setIsDrawerOpened={props.setIsParticipantDrawerOpened}
                participants={props.participants?.participants}
                session={session}
                onLeaveOrJoinSession={props.onLeaveOrJoinSession}
                community={community}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={11}>
            {props.status === "connected" && (
              <VideoFooter
                className="video-operations"
                status={props.status}
                actionText={props.actionText}
                onLeaveOrJoinSession={props.onLeaveOrJoinSession}
                mediaStream={props.mediaStream}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </footer>
  );
}
