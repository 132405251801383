import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Card, Typography } from "@material-ui/core";
import { toggleDrawer } from "../../../store/store";
import "./landing.scss";

export default function Landing(props: any) {
  const drawer = useSelector(toggleDrawer);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    props.setIsParticipantDrawerOpened(!props.isParticipantDrawerOpened)
  };

  return (
    <Box className="landing-box">
      <Box className="welcome">
        <Typography variant="subtitle1" component="div">
          Welcome To LCV
        </Typography>
        <br />
        <Button variant="outlined" onClick={handleClickOpen} className="button">
          SELECT PARTICIPANTS
        </Button>
      </Box>
    </Box>
  );
}
