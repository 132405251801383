import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export function typedAction<T extends string>(type: T): { type: T };

export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P };

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export const initialState = {
    email: "",
    id: null,
    image_url: "",
    large_image_url: "",
    name: "",
    phone: "",
    square_image_url: "",
    thumb_image_url: "",
    uuid: ""
};

export const CommunitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    storeCommunity: (state, action: PayloadAction<typeof typedAction>) => {
      return { ...state, ...action.payload };
    },
  },
});

// export type RootState = ReturnType<typeof store.getState>;

export const { storeCommunity } = CommunitySlice.actions

export const selectedCommunity = (state: any) => state.community;

export default CommunitySlice.reducer;