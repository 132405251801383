import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export function typedAction<T extends string>(type: T): { type: T };

export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P };

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export const initialState = {
  identity: "",
  token: "",
  sessionId: ""
};

export const SessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    storeSession: (state, action: PayloadAction<typeof typedAction>) => {
      return { ...state, ...action.payload };
    },
    storeSessionId: (state, action: PayloadAction<string>) => {
      return { ...state, sessionId: action.payload };
    },
  },
});

export const { storeSession, storeSessionId } = SessionSlice.actions;

export const currentSession = (state: any) => state.session;

export default SessionSlice.reducer;
